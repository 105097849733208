import React from 'react'
import DogModel from './DogModel'
import handleNav from '../utils/handleNav'

const Home = () => {
  return (
    <section id='home'>
      <img src='/static/sparkles.svg' alt='sparkles' className='animate-pulse1' />
      <img src='/static/sparkles.svg' alt='sparkles' className='animate-pulse2' />
      <div className='home_content'>
        <p className='greet'>Hi, I am</p>
        <h1 className='gradient name'>Saravanan Balasubramaniam</h1> <span className='wave'>👋</span>
        <p className='designation'>Frontend Developer</p>
        <div>
          <button className='more_about' onClick={() => handleNav('#about')}>
            More about me
          </button>
          <button className='connect' onClick={() => handleNav('#contact')}>
            Let's connect
          </button>
        </div>
      </div>
      <div>
        <DogModel />
      </div>
    </section>
  )
}

export default Home
