import React from 'react'

const Title = ({ title }) => {
  return (
    <div className='titleContainer'>
      <h1 className='gradient'>{title}</h1>
      <p className='dash'></p>
    </div>
  )
}

export default Title
