import React, { useState, useEffect } from 'react'
import { CloseIcon, DarkIcon, LightIcon, OpenIcon } from '../utils/Icons'
import { themeColor } from '../utils/themeColor'
import handleNav from '../utils/handleNav'
import ProfilePic from '../assets/profile.jpeg'

const navItems = [
  {
    name: 'Home',
    path: '#home',
  },
  {
    name: 'About',
    path: '#about',
  },
  {
    name: 'Techs',
    path: '#tech',
  },
  {
    name: 'Experience',
    path: '#experience',
  },
  // {
  //   name: 'Projects',
  //   path: '#projects',
  // },
  {
    name: 'Contact',
    path: '#contact',
  },
]
function Header() {
  const [nav, setNav] = useState(false)
  const [mode, setMode] = useState('dark')
  const [active, setActive] = useState(false)

  const handleScroll = (e) => {
    window.scrollY > 100 ? setNav(true) : setNav(false)
  }

  useEffect(() => {
    // http://localhost:3000/?project=yaazh_front ( navigate to yaazh_front id)
    const queryParams = new URLSearchParams(window.location.search)
    const yaazhValue = queryParams.get('project')
    handleNav(`#${yaazhValue}`)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <header id='header' className={`${nav ? 'active' : ''}`}>
      <div className='container'>
        <div className='flex align-items-center justify-content-between'>
          <div className='flex align-items-center justify-content-center'>
            <img src={ProfilePic} alt='profile' className='header_logo' />
            <p className='header_logo_name'>Saravana's Portfolio</p>
          </div>
          <nav>
            <div className='large_screen'>
              <ul>
                {navItems.map((item) => (
                  <li key={item.name} onClick={() => handleNav(item.path)}>
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>

            <div className='small_screen'>
              <button onClick={() => setActive((active) => !active)}>
                {!active ? <OpenIcon className='pointer rootTextSvg' /> : <CloseIcon className='pointer rootTextSvg' />}
              </button>
              <ul className={active ? 'active' : ''}>
                {navItems.map((item) => (
                  <li key={item.name} onClick={() => handleNav(item.path)}>
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>

            {/* {mode === 'dark' ? (
                <LightIcon
                  className='svg-25 pointer ml-50'
                  onClick={(e) => {
                    setMode('light')
                    themeColor('light')
                  }}
                />
              ) : (
                <DarkIcon
                  className='svg-25 pointer ml-50'
                  onClick={(e) => {
                    setMode('dark')
                    themeColor('dark')
                  }}
                />
              )} */}
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
