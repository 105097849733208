import './App.css'
import Header from './components/Header'
import About from './components/About'
import Experience from './components/Experience'
import Project from './components/Project'
import Contact from './components/Contact'
import Home from './components/Home'
import Tech from './components/Tech'

function App() {
  return (
    <div>
      <Header />
      <div className='container'>
        <Home />
        <About />
        <Tech />
        <Experience />
        {/* <Project /> */}
        <Contact />
      </div>
    </div>
  )
}

export default App
