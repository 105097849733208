import React from 'react'
import Title from './Title'

const UI = [
  {
    name: 'HTML',
    image: 'html.svg',
  },
  {
    name: 'CSS',
    image: 'css.svg',
  },
  {
    name: 'Bootstrap',
    image: 'bootstrap.svg',
  },
  // {
  //   name: 'SASS',
  //   image: 'sass.svg',
  // },
  {
    name: 'Material UI',
    image: 'material-ui.svg',
  },
  {
    name: 'Three.js',
    image: 'threejs.png',
  },
]
const frontend = [
  {
    name: 'JavaScript',
    image: 'javascript.svg',
  },
  {
    name: 'React',
    image: 'react.svg',
  },
  {
    name: 'NextJs',
    image: 'next.svg',
  },
  {
    name: 'Wordpress',
    image: 'wordpress.svg',
  },
]

const UX = [
  {
    name: 'Figma',
    image: 'figma.svg',
  },
  {
    name: 'Adobe Photoshop',
    image: 'adobe-photoshop.svg',
  },
]

const Mobile = [
  {
    name: 'Flutter',
    image: 'flutter.svg',
  },
]

const cicd = [
  {
    name: 'Jenkins',
    image: 'jenkins.png',
  },
  {
    name: 'Sonarqube',
    image: 'sonarqube.svg',
  },
]

const others = [
  {
    name: 'Git',
    image: 'git.svg',
  },
  {
    name: 'Aws (S3)',
    image: 'aws-s3.png',
  },
  {
    name: 'AWS CloudFront',
    image: 'aws.svg',
  },
  {
    name: 'Jira',
    image: 'jira.svg',
  },
  {
    name: 'Auth0',
    image: 'auth0.png',
  },
]
// {
//   name: 'MySql',
//   image: 'mysql.svg',
// },
// {
//   name: 'Postgresql',
//   image: 'postgresql.svg',
// },
// {
//   name: 'Redis (Caching)',
//   image: 'redis.svg',
// },
// {
//   name: 'Docker',
//   image: 'docker.svg',
// },
// {
//   name: 'Github Actions (CI/CD)',
//   image: 'github-actions.svg',
// },

const Tech = () => {
  return (
    <section id='tech'>
      <Title title='Techs Used' />

      <div className='tech-sub-head'>UI</div>
      <div className='flex flex-wrap'>
        {UI.map((tech) => (
          <button key={tech.name}>
            <img src={`/stack/${tech.image}`} alt={tech.name} />
            <p>{tech.name}</p>
          </button>
        ))}
      </div>

      <div className='tech-sub-head'>UX</div>
      <div className='flex flex-wrap'>
        {UX.map((tech) => (
          <button key={tech.name}>
            <img src={`/stack/${tech.image}`} alt={tech.name} />
            <p>{tech.name}</p>
          </button>
        ))}
      </div>

      <div className='tech-sub-head'>Frontend</div>
      <div className='flex flex-wrap'>
        {frontend.map((tech) => (
          <button key={tech.name}>
            <img src={`/stack/${tech.image}`} alt={tech.name} />
            <p>{tech.name}</p>
          </button>
        ))}
      </div>

      <div className='tech-sub-head'>Mobile Development</div>
      <div className='flex flex-wrap'>
        {Mobile.map((tech) => (
          <button key={tech.name}>
            <img src={`/stack/${tech.image}`} alt={tech.name} />
            <p>{tech.name}</p>
          </button>
        ))}
      </div>

      <div className='tech-sub-head'>CI/CD</div>
      <div className='flex flex-wrap'>
        {cicd.map((tech) => (
          <button key={tech.name}>
            <img src={`/stack/${tech.image}`} alt={tech.name} />
            <p>{tech.name}</p>
          </button>
        ))}
      </div>

      <div className='tech-sub-head'>Others</div>
      <div className='flex flex-wrap'>
        {others.map((tech) => (
          <button key={tech.name}>
            <img src={`/stack/${tech.image}`} alt={tech.name} />
            <p>{tech.name}</p>
          </button>
        ))}
      </div>
    </section>
  )
}

export default Tech
