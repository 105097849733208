import { Suspense } from 'react'
import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Dog } from './Dog'
import { useState, useEffect } from 'react'
import Spinner from '../Spinner'

const DogModel = () => {
  //   const [autoRotate, setAutoRotate] = useState(false);
  const [autoRotateSpeed, setAutoRotateSpeed] = useState(0.01)

  useEffect(() => {
    setAutoRotateSpeed(800)

    setTimeout(() => {
      setAutoRotateSpeed(2)
    }, 800)
  }, [])
  return (
    <div className='dogModel'>
      <Suspense fallback={<Spinner />}>
        <Canvas
          camera={{ position: [15, 8, 28], fov: 22 }}
          style={{ width: '100%', height: '100%', background: '#171717' }}
        >
          <pointLight position={[5, 5, 5]} intensity={0.5} />
          <pointLight position={[-3, -3, 2]} intensity={0.5} />
          <ambientLight position={[5, 5, 5]} intensity={0.5} />
          <directionalLight intensity={0.2} />
          <Dog />
          <OrbitControls autoRotate autoRotateSpeed={autoRotateSpeed} />
        </Canvas>
      </Suspense>
    </div>
  )
}
export default DogModel
