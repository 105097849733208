import React, { useState, useEffect } from 'react'
import emailjs from 'emailjs-com'
import Title from './Title'
import { LinkedIcon } from '../utils/Icons'
import Spinner from './Spinner'

const Contact = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState('')

  function sendEmail(e) {
    e.preventDefault()
    setLoading(true)
    emailjs.sendForm('service_exfqxdd', 'template_1bej09u', e.target, 'J3-GS2VRi_XMvxwX_').then(
      (result) => {
        console.log(result.text)
        setLoading(false)
        setAlert('Message was sent')
      },
      (error) => {
        console.log(error.text)
        setLoading(false)
        setAlert('Message was not sent. Try again')
      }
    )

    setName('')
    setSubject('')
    setEmail('')
    setMessage('')
    setTimeout(() => {
      setAlert('')
    }, 4000)
  }

  useEffect(() => {
    setTimeout(() => {
      setAlert('')
    }, 3000)
  }, [alert])

  return (
    <section id='contact'>
      <Title title='Get In Touch' />

      <div className='flex small-flex-wrap'>
        <div className='contact_info'>
          <p className='contact_para'>
            I'm always open to new projects and opportunities. Whether you're a company looking to hire or you're just
            looking to chat, I'd love to hear from you.
          </p>
          <div className='flex align-items-center mb-20'>
            <img src='/static/mail.svg' alt='mail' className='contact_svg' />
            <p className='contact_detail'>saravana282008@gmail.com</p>
          </div>
          <div className='flex align-items-center mb-20'>
            <img src='/static/call.svg' alt='mail' className='contact_svg' />
            <p className='contact_detail'>9840490629</p>
          </div>
          <div className='flex align-items-center mb-20'>
            <a href='https://www.linkedin.com/in/saravanan-b-8a613111a/' target='blank'>
              <LinkedIcon className={'svg-color'} />
            </a>
          </div>
        </div>

        <div className='contact_inputs'>
          <form onSubmit={sendEmail}>
            <div className='input_combine'>
              <div className='form__group field'>
                <input
                  type='text'
                  placeholder='name'
                  className='form__field'
                  name='name'
                  id='contactName'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <label htmlFor='contactName' className='form__label'>
                  Name
                </label>
              </div>

              <div className='form__group field'>
                <input
                  type='email'
                  placeholder='email'
                  className='form__field'
                  name='email'
                  id='contactEmail'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label htmlFor='contactEmail' className='form__label'>
                  Email
                </label>
              </div>
            </div>

            <div className='form__group field'>
              <input
                type='text'
                placeholder='subject'
                className='form__field'
                name='subject'
                id='contactSubject'
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <label htmlFor='contactSubject' className='form__label'>
                Subject
              </label>
            </div>

            <div className='form__group field'>
              <textarea
                cols='50'
                rows='3'
                value={message}
                placeholder='message'
                className='form__field'
                name='message'
                id='contactMessage'
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              <label htmlFor='contactMessage' className='form__label'>
                Message
              </label>
            </div>

            <div>
              <button type='submit' className='submit' disabled={loading ? true : false}>
                {loading ? 'loading...' : 'Send Message'}
              </button>
            </div>
          </form>

          <div className='alert'>{alert && alert}</div>
        </div>
      </div>
    </section>
  )
}

export default Contact
