const handleNav = (path) => {
  const headerCoords = document.querySelector('#header').getBoundingClientRect()
  const section1 = document.querySelector(path)
  const s1coords = section1?.getBoundingClientRect()
  if (s1coords) {
    window.scrollTo({
      top: s1coords.top + window.pageYOffset - (headerCoords.height + 20),
      behavior: 'smooth',
    })
  }
}

export default handleNav
