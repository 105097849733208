import React from 'react'
import Title from './Title'

const About = () => {
  return (
    <div id='about'>
      <Title title='About' />
      <div className='flex align-items-center small-flex-wrap'>
        <div>
          <p className='about_para'>
            In the world of web development, my journey began with the classic "Hello World" console output in the
            browser, igniting a profound passion for crafting exceptional web experiences. Since then, I have been on a
            relentless quest to learn and receive feedback, continuously improving myself.
          </p>

          <p className='about_para'>
            With years of experience under my belt, I specialize in leveraging cutting-edge technologies to build
            powerful and scalable web applications. Collaborating with dynamic teams to create remarkable web
            experiences that contribute positively to the digital landscape is what drives me.
          </p>

          <p className='about_para'>
            I am always eager to embrace new opportunities for learning and personal growth as a developer. If you seek
            a driven and enthusiastic team player to collaborate on building extraordinary web applications, I'm excited
            to join forces and create something amazing together!
          </p>
        </div>
        <div className='about_profile'>
          <img src='/static/profile.jpeg' alt='profile' className='profile' />
          <a className='resume' href='/resume.pdf' download='resume.pdf'>
            Download Resume
          </a>
        </div>
      </div>
    </div>
  )
}

export default About
