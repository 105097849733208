import React from 'react'
import Title from './Title'

const company_projects = [
  {
    name: `Sea Dwelling Creatures`,
    image: `/static/seadwelling_creatures.png`,
    desc: `At a leading global wholesaler of marine and aquarium supplies, I contributed to a critical project involving the migration of code from PHP to Node.js, significantly enhancing performance. Implemented database transactions to ensure data integrity across various tables and seamlessly integrated third-party APIs such as Avalara (tax) and ShipEngine (shipping). Effectively monitored and optimized weekly Saturday sales and revenue, consistently surpassing $70,000 per week. Managed high traffic volumes with New Relic for comprehensive application monitoring.`,
    techs: `Node.js, Express, TPL, React, Redux, Bootstrap, WebSockets, Redis, MySQL, Cron, New Relic`,
  },
  {
    name: `Paymentgateway.com`,
    image: `/static/auctionsoftware.png`,
    desc: `As a part of my role, I successfully implemented payment intents and payouts while working with white-labeled software from Stripe and efficiently managed disputes . Additionally, I integrated Stripe Terminal into our system, enabling robust point-of-sale (POS) capabilities and facilitating smooth offline payments through card readers.`,
    techs: `Stripe, POS (Point of Sale), webhooks, Node.js, Express, React, Material UI, MySQL`,
  },
]

const Experience = () => {
  return (
    <div id='experience'>
      <Title title='Experience' />

      <div className=''>
        <div className='company_name'>
          <div className='flex align-items-center'>
            <img src='/static/tisteps.png' alt='Accenture' width={20} />
            <span>Tisteps India Pvt Ltd - Frontend Developer</span>
          </div>
          <p>2020/01 - Present</p>
        </div>
        <div style={{ color: 'var(--rootTextColorLight', margin: '20px 0 40px 20px' }}>
          <div>Best Employee for Outstanding Performance in September 2023.</div>
          <ul style={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}>
            <li>
              Collaborated with cross-functional teams including designers and backend developers to implement user
              interface features for web applications.
            </li>
            <li>Utilized HTML, SCSS, React, and Next.js to develop responsive and scalable front-end solutions.</li>
            <li>Implemented animations and transitions to enhance user interactions, using CSS3 and JavaScript.</li>
            <li>
              Optimized web performance through techniques such as lazy loading, code splitting, and image optimization.
            </li>
            <li>
              Conducted code reviews and provided constructive feedback to maintain code quality and adherence to best
              practices.
            </li>
            <li>Actively participated and contributed to sprint planning, stand-ups, and retrospectives.</li>
          </ul>
        </div>
      </div>

      <div className=''>
        <div className='company_name'>
          <div className='flex align-items-center'>
            <img src='/static/spi.jpeg' alt='develop_scripts' width={25} />
            <span>SPI Global India Private Ltd - Senior Quality Control Engineer</span>
          </div>
          <p>2016/03 – 2019/11</p>
        </div>
        <div style={{ color: 'var(--rootTextColorLight', margin: '20px 0 40px 20px' }}>
          <ul style={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}>
            <li>
              Designed an intuitive user interface for an online test platform catered to college students in Australia
              using React and JavaScript.
            </li>
            <li>Strong in HTML, CSS, and XML for digital content formatting</li>
            <li>
              Developed and implemented quality assurance procedures to streamline workflows and improve efficiency
            </li>
            <li>
              Conducted testing and debugging to ensure the stability and reliability of e-publishing applications
            </li>
            <li>
              Collaborated with authors, editors, and designers to address feedback and implement quality improvements
            </li>
            <li>Generated reports and maintained documentation of quality control processes and findings</li>
            <li>Assisted in the development and implementation of e-publishing best practices and standards</li>
            <li>Provided training and support to authors and staff on e-publishing tools and techniques</li>
          </ul>
        </div>
      </div>
      <div className='company_name'>
        <div className='flex align-items-center'>
          <img src='/static/aps.jpeg' alt='develop_scripts' width={25} />
          <span>Antares Publishing Services Pvt Ltd - Senior Quality Control Engineer</span>
        </div>
        <p>2014/04 – 2016/03</p>
      </div>
      <div style={{ color: 'var(--rootTextColorLight', margin: '20px 0 40px 20px' }}>
        <ul style={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}>
          <li>Experienced in e-publishing standards and formats such as EPUB and PDF</li>
          <li>Proficient in HTML, CSS, and XML for digital content formatting</li>
          <li>Established and executed quality assurance procedures to enhance workflows and efficiency</li>
          <li>Performed testing and debugging to guarantee stability and reliability of e-publishing applications</li>
          <li>Collaborated closely with authors, editors, and designers to incorporate feedback and enhance quality</li>
          <li>Generated reports and upheld documentation of quality control processes and discoveries</li>
          <li>Contributed to the formulation and adoption of e-publishing best practices and standards</li>
          <li>Offered training and assistance to authors and staff on e-publishing tools and methodologies</li>
        </ul>
      </div>
      <div className='company_name'>
        <div className='flex align-items-center'>
          <img src='/static/ament.jpg' alt='develop_scripts' width={25} />
          <span>Ament System Private Ltd - Senior Quality Control Engineer</span>
        </div>
        <p>2011/11 – 2013/06</p>
      </div>
      <div style={{ color: 'var(--rootTextColorLight', margin: '20px 0 40px 20px' }}>
        <ul style={{ paddingLeft: '1rem', paddingTop: '0.5rem' }}>
          <li>
            Authored and published [number] children's books in various genres, including picture books, early readers,
            and middle-grade fiction
          </li>
          <li>Developed engaging characters and storylines that resonate with young readers</li>
          <li>Familiarity with e-publishing standards and formats, including EPUB and PDF</li>
          <li>Strong in HTML, CSS, and XML for digital content formatting</li>
          <li>Developed and implemented quality assurance procedures to streamline workflows and improve efficiency</li>
          <li>Conducted testing and debugging to ensure the stability and reliability of e-publishing applications</li>
          <li>
            Collaborated with authors, editors, and designers to address feedback and implement quality improvements
          </li>
          <li>Generated reports and maintained documentation of quality control processes and findings</li>
          <li>Assisted in the development and implementation of e-publishing best practices and standards</li>
          <li>Provided training and support to authors and staff on e-publishing tools and techniques</li>
        </ul>
      </div>
      <div className='company_name'>
        <div className='flex align-items-center'>
          <img src='/static/develop_scripts.png' alt='develop_scripts' width={25} />
          <span>Zen Data Services - Trainee</span>
        </div>
        <p>2010/05 – 2011/03</p>
      </div>
    </div>
  )
}

export default Experience
